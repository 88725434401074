@tailwind base;
@tailwind components;
@tailwind utilities;

.glassmorphism-card {
    backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.3);
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  }